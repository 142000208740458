<template>
  <div v-if="inline">
    <p>
      <a :href="helpCenterUrl" target="_blank" class="d-flex-center">
        <fluency-icon type="help" size="lg" class="mr-2" /> View past feature releases <fluency-icon type="linkExternal" class="ml-2" />
      </a>
    </p>
    <new-feature-guide-items :guides="guides"
                             :displayed-guides="displayedGuides"
                             :visible-index="visibleIndex"
                             :notifications="notifications" />
  </div>
  <div v-else>
    <b-dropdown-item v-if="whatFixEnabled"
                     class="position-relative"
                     :link-class="linkClasses"
                     @click="modalOpen = true">
      <fluency-icon v-if="icon" :type="icon" class="mr-2" />
      Release Notes
      <notification-badge v-if="notifications > 0" color="info" relative>{{ notifications }}</notification-badge>
    </b-dropdown-item>
    <header-icon-notifications v-else
                               :notifications="notifications"
                               class="new-feature-guide"
                               icon-type="crossroads"
                               title="New Features"
                               @click="modalOpen = true"></header-icon-notifications>
    <b-modal v-model="modalOpen"
             id="user-coach-sidebar"
             ok-only
             size="sm">
      <template #modal-header>
        <div class="d-flex flex-column">
          <h3 class="mt-3">New Feature Release Guide</h3>
          <a :href="helpCenterUrl" target="_blank" class="mt-3 d-flex-center">
            <fluency-icon type="help" size="lg" class="mr-2" /> View past feature releases <fluency-icon type="linkExternal" class="ml-2" />
          </a>
        </div>
      </template>
      <div class="p-3">
        <new-feature-guide-items :guides="guides"
                                 :displayed-guides="displayedGuides"
                                 :visible-index="visibleIndex"
                                 :notifications="notifications" />
      </div>
    </b-modal>
  </div>
</template>

<script>
import HeaderIconNotifications from '~/components/common/headerIconNotifications'
import NewFeatureGuideItems from './newFeatureGuideItems'
import NotificationBadge from '@/components/common/notificationBadge'

export default {
  name: 'newFeatureGuide',
  components: { HeaderIconNotifications, NewFeatureGuideItems, NotificationBadge },
  props: {
    inline: {
      type: Boolean,
      default: false
    },
    notificationCount: {
      type: Number // 2 way
    },
    linkClasses: {
      type: String,
      default: ''
    },
    icon: {
      type: String,
      default: ''
    }
  },
  data () {
    return {
      helpCenterUrl: 'https://help.fluency.inc/en/collections/8973890-what-s-new',
      viewedItems: [],
      modalOpen: false
    }
  },
  computed: {
    coachmarks () {
      return this?.$store?.getters.userCoachmarks || []
    },
    guides () {
      return this?.coachmarks?.filter(cm => cm.type === 'GUIDE') || []
    },
    notifications () {
      return this.guides.length - this.guides.filter(g => g.status === 'REMOVED').length
    },
    visibleIndex () {
      if (this.guides.length === 1) {
        return 0
      } else if (this.displayedGuides.findIndex(cm => cm.visible) > -1) {
        return this.displayedGuides.findIndex(cm => cm.visible)
      } else if (this.displayedGuides.findIndex(cm => cm.status === 'ENABLED') > -1) {
        return this.displayedGuides.findIndex(cm => cm.status === 'ENABLED')
      } else {
        return this.displayedGuides.findIndex(cm => cm.status === 'REMOVED')
      }
    },
    displayedGuides () {
      const newGuides = this.guides.filter(g => g.status === 'ENABLED').sort((a, b) => a.created_time - b.created_time)
      const oldGuides = this.guides.filter(g => g.status === 'REMOVED').sort((a, b) => a.created_time - b.created_time)
      if (oldGuides.length === 0) {
        return newGuides
      } else {
        return [...newGuides, { separator: true }, ...oldGuides]
      }
    },
    whatFixEnabled () {
      return this.$store.getters.user?.capabilities?.WhatFix
    }
  },
  methods: {
    async itemViewed (viewedCm) {
      if (viewedCm.status !== 'REMOVED') {
        await this.$res.set.userCoachmark(viewedCm)
        this.$store.commit('userCoachmarks', [{ ...viewedCm, status: 'REMOVED' }, ...this.coachmarks.filter(cm => cm.definitionId !== viewedCm.definitionId)])
      }
    }
  },
  watch: {
    notifications: {
      immediate: true,
      handler (val) {
        this.$emit('update:notificationCount', val)
      }
    }
  }
}
</script>

<style lang='scss' scoped>

</style>

<style lang='scss'>
#user-coach-sidebar {
  p {
    font-size: .9rem;
  }
}
</style>
