<template>
  <div>
    <div class="d-flex-center-end">
      <b-button v-if="authoring" variant="ghost-secondary" @click="cancelAuthoring()">
        Cancel
      </b-button>
      <b-button v-if="authoring && authoring.definitionId" variant="ghost-danger" @click="save(true)">
        Delete
      </b-button>
      <b-button v-if="authoring" variant="ghost-primary" @click="save()">
        Save
      </b-button>
      <b-button v-if="canEdit && !authoring" variant="ghost-primary" @click="startAuthoring">
        <fluency-icon type="add"></fluency-icon>
      </b-button>
    </div>
    <div v-if="authoring">
      <div class="mb-5">
        <label>Title</label>
        <div class="mad-lib-container">
          <basic-mad-lib-input v-model="authoring.title" fill-width></basic-mad-lib-input>
        </div>
      </div>
      <div class="mb-5">
        <label>Content</label>
        <markdown-input v-model="authoring.content"></markdown-input>
      </div>
    </div>
    <template v-else>
      <template v-if="notifications === 0">
        <div class="text-center font-weight-normal m-5">
          <fluency-icon type="takeABreak" class="w-33 mx-auto d-block"/>
          Nothing new here. Take a break, and check back later for info about new feature releases!
        </div>
      </template>
      <template v-if="guides.length > 0">
        <template v-for="(guide, index) in displayedGuides" :key="guide.definitionId">
            <span v-if="guide.separator"
                  class="d-block border-bottom text-muted">You've Already Viewed These</span>
          <collapsible-card v-else :visible="index === visibleIndex" :key="guide.definitionId">
            <template #header>
              <div class="d-flex-center">
                <span>{{guide.title}}</span>
                <b-button v-if="canEdit" variant="link" class="ml-auto p-0" @click="startAuthoring(guide)">
                  <fluency-icon type="edit"></fluency-icon>
                </b-button>
              </div>
            </template>
            <div v-markdown="guide.content"/>
          </collapsible-card>
        </template>
      </template>
    </template>
  </div>
</template>

<script>
import CollapsibleCard from './widgets/collapsibleCard'
import BasicMadLibInput from 'core-ui/components/inputs/basicMadLibInput'
import MarkdownInput from '@/components/common/markdownInput'
export default {
  name: 'newFeatureGuideItems',
  components: { MarkdownInput, CollapsibleCard, BasicMadLibInput },
  props: {
    guides: {
      type: Array,
      default: () => []
    },
    displayedGuides: {
      type: Array,
      default: () => []
    },
    visibleIndex: {
      type: Number,
      default: 0
    },
    notifications: {
      type: Number,
      default: 0
    }
  },
  data () {
    return {
      authoring: undefined
    }
  },
  computed: {
    canEdit () {
      const user = this.$store.getters.user
      return user?.internalUser && user?.capabilities?.EditReleaseNotes
    }
  },
  methods: {
    startAuthoring (guide) {
      if (guide?.definitionId) {
        this.authoring = { ...guide }
      } else {
        this.authoring = {
          title: '',
          content: '',
          type: 'GUIDE'
        }
      }
    },
    cancelAuthoring () {
      this.authoring = undefined
    },
    stripSpanTag (str) {
      if (!str) return str
      if (str.startsWith('<span>') && str.endsWith('</span>')) {
        return str.substring(6, str.length - 7)
      }
      return str
    },
    async save (remove) {
      const status = remove ? 'REMOVED' : 'ENABLED'
      const resp = await this.$res.set.coachmark({ ...this.authoring, status })
      if (resp) {
        this.authoring = undefined
        this.$store.dispatch('fetchUserCoachmarks')
      }
    }
  }
}
</script>

<style lang="scss" scoped>

</style>
